<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-white mb-0 mr-custom ml-custom">
            <li class="breadcrumb-item">
                <router-link to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24.016" height="21.438" viewBox="0 0 24.016 21.438">
                        <g id="home" transform="translate(0.299 0.27)">
                            <g id="Group_38350" data-name="Group 38350" transform="translate(0 0)">
                                <g id="Group_38349" data-name="Group 38349">
                                    <path id="Path_66878" data-name="Path 66878"
                                          d="M23.217,36.06,12.093,27.915a.61.61,0,0,0-.72,0L.249,36.06a.61.61,0,1,0,.72.984l10.763-7.881L22.5,37.044a.61.61,0,0,0,.72-.984Z"
                                          transform="translate(0.001 -27.797)"
                                          fill="#0e5aa6" stroke="#0e5aa6" stroke-width="0.5"/>
                                </g>
                            </g>
                            <g id="Group_38352" data-name="Group 38352" transform="translate(2.587 9.384)">
                                <g id="Group_38351" data-name="Group 38351" transform="translate(0 0)">
                                    <path id="Path_66879" data-name="Path 66879"
                                          d="M74.134,232.543a.61.61,0,0,0-.61.61v9.705H68.647v-5.3a3.049,3.049,0,0,0-6.1,0v5.3H57.671v-9.705a.61.61,0,1,0-1.219,0v10.315a.61.61,0,0,0,.61.61h6.1a.609.609,0,0,0,.607-.562.46.46,0,0,0,0-.048V237.56a1.829,1.829,0,1,1,3.659,0v5.907a.446.446,0,0,0,0,.047.609.609,0,0,0,.607.563h6.1a.61.61,0,0,0,.61-.61V233.153A.61.61,0,0,0,74.134,232.543Z"
                                          transform="translate(-56.452 -232.543)" fill="#0e5aa6" stroke="#0e5aa6"
                                          stroke-width="0.5"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{ currentPage }}
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
    name: 'BreadCrumb',
    props: ['currentPage']
}
</script>

<style>
.breadcrumb-item svg {
    height: 20px;
    vertical-align: baseline;
}


[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
    float: unset;
    font-size: 15px;
    font-weight: 900;
    content: '\f053';
    color: var(--primary-color);
    font-family: "Font Awesome 5 Free";
    padding-left: .5rem;
}

.breadcrumb-item:not(:first-of-type) {
    font-size: 1.375rem;
    font-weight: bold;
    color: #000;
}

</style>