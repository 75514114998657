<template>
    <BaseLayout>
        <template #page-content>
            <BreadCrumb current-page="تواصل معنا"/>
            <div class="all">
                <div class="container-fluid">
                    <div class="mr-custom ml-custom">
                        <section class="contact-us">
                            <h2 class="sub-header mb-3">{{ $t('nav.contact-us') }}</h2>
                            <div class="row">
                                <div class="col-lg-4 col-12">
                                    <form action="" class="contact-form">
                                        <div class="form-border" aria-hidden="true"></div>
                                        <h3 class="text-center">{{ $t('contact.content') }}</h3>

                                        <div class="form-group">
                                            <label for="username">{{ $t('Name') }}</label>
                                            <input type="text" class="form-control" id="username" v-model="name"
                                                   required>
                                        </div>

                                        <div class="form-group">
                                            <label for="email">{{ $t('Email Address') }}</label>
                                            <input type="email" class="form-control" id="email" v-model="email"
                                                   required>
                                        </div>

                                        <div class="form-group">
                                            <label for="title">{{ $t('Subject') }}</label>
                                            <input type="text" class="form-control" id="title" v-model="subject"
                                                   required>
                                        </div>

                                        <div class="form-group">
                                            <label>{{ $t('contact.message') }}</label>
                                            <textarea v-model="message" class="form-control"></textarea>
                                        </div>

                                        <div class="text-center">
                                            <button class="btn btn-save btn-send" type="submit" @click="sendMsg"
                                                    :disabled="loading">{{ $t('contact.send') }}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-lg-8 col-12 align-self-center text-center">
                                    <img src="@/assets/img/contact-us.png" alt="">

                                    <div class="text-center">

                                        <a :href="`https://api.whatsapp.com/send?phone=${settings.mobile}`" class="btn btn-success mt-4">
                                            {{ $t('direct_contact') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </template>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/containers/BaseLayout";
import BreadCrumb from "@/components/BreadCrumb";
import {contact} from "../services/auth";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";

export default {
    name: "Contact-us",
    components: {BreadCrumb, BaseLayout},
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data() {
        return {
            toast: useToast(),
            name: '',
            email: '',
            subject: '',
            message: '',
            loading: false
        }
    },
    computed: {
        ...mapGetters(['settings'])
    },
    methods: {
        sendMsg() {
            let data = {
                name: this.name,
                email: this.email,
                title: this.subject,
                message: this.message
            }
            console.log(data)
            let success_msg = this.$i18n.locale === 'ar' ? 'تم إرسال رسالتك بنجاح' : 'Message Send successfully'

            this.loading = true;
            contact(data).then(() => {
                this.loading = false
                this.toast.success(success_msg);
            })
        }
    }
}
</script>

<style scoped>

</style>